import { Component, Vue, Ref } from "vue-property-decorator";
import i18n from "@/i18n/i18n";
import DataTable from "@/components/DataTableGenerico/DataTable.vue";
import {
  DxEditing,
  DxPopup,
  DxPosition,
  DxForm,
  DxFormItem,
} from "devextreme-vue/data-grid";
import { DxItem } from "devextreme-vue/form";
import { MessageService } from "@/shared/services/message-service";

@Component({
  components: {
    DataTable,
    DxEditing,
    DxPopup,
    DxPosition,
    DxForm,
    DxFormItem,
    DxItem,
  },
})
export default class BaseListaClass extends Vue {
  public NombrePantalla: string = "";
  public ClonarVisible: boolean = true;
  public EstoyEditando: boolean = false;
  public EstoyCreado: boolean = false;
  public readonlyform: boolean = false;
  public HeGuardado: boolean = false;

  @Ref("DataTable") DataTable!: DataTable;
  public created() {
    try {
      this.NombrePantalla = i18n
        .t(this.$router.currentRoute.meta.i18n)
        .toString();
    } catch (error) { }
    this.BeforeRefresh();
    this.Refresh();
    this.onCreated();
  }

  public get VisibleClonar(): boolean {
    if (!this.ClonarVisible) {
      this.ClonarVisible = true;
    }
    return this.ClonarVisible;
  }
  public get toolbarItems() {
    return [
      {
        toolbar: "bottom",
        location: "before",
        widget: "dxButton",
        options: {
          onClick: (e: any) => {
            e.element.style.visibility = "hidden";
            this.ClonarVisible = false;
          },
          text: this.$i18n.t("clonar"),
          icon: "fa fa-clone",
          stylingMode: "outlined",
          visible: this.VisibleClonar,
        },
      },
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          onClick: () => {
            //@ts-ignore lo ignoro porque si que esta tipado y funciona pero no se reconoce
            if (this.DataTable.VisualDxDataGrid.instance !== undefined) {
              //@ts-ignore lo ignoro porque si que esta tipado y funciona pero no se reconoce
              this.DataTable.VisualDxDataGrid.instance.saveEditData();
            }
          },
          text: this.$i18n.t("guardar"),
          icon: "fa fa-check",
          type: "success",
          stylingMode: "outlined",
        },
      },
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          onClick: () => {
            //@ts-ignore lo ignoro porque si que esta tipado y funciona pero no se reconoce
            if (this.DataTable.VisualDxDataGrid.instance !== undefined) {
              //@ts-ignore lo ignoro porque si que esta tipado y funciona pero no se reconoce
              this.DataTable.VisualDxDataGrid.instance.cancelEditData();
            }
          },
          text: this.$i18n.t("cancelar"),
          icon: "fa fa-times",
          type: "danger",
          stylingMode: "outlined",
        },
      },
    ];
  }
  public ErrorEliminado() {
    MessageService.toast(
      "El registro no se ha podido eliminar",
      MessageService.TypeWarning
    );
  }
  public BeforeRefresh() { }
  public Refresh() { }
  public onCreated() { }
}
